import { fetchJSON } from "./utils";

async function getInput(id) {
  const path = `/input/${id}`;
  return fetchJSON(path, 'GET', {});
}

async function getInputs() {
  const path = '/input';
  return fetchJSON(path, 'GET', {});
}

async function upsertInput({ id, name, data_type, required, permitted_values, behavior }) {
  if (id) {
    const path = `/input/${id}`;
    const body = { name, data_type, required, permitted_values, behavior };
    await fetchJSON(path, 'PATCH', { body: JSON.stringify(body) });
    return id;
  }

  const path = '/input';
  const body = { name, data_type, required, permitted_values, behavior };
  const res = await fetchJSON(path, 'POST', { body: JSON.stringify(body) });
  return res.data;
}

async function deleteInput(id) {
  const path = `/input/${id}`;
  return await fetchJSON(path, 'DELETE', {});
}

export {
  getInput,
  getInputs,
  upsertInput,
  deleteInput
}
