import { Card, Flex, Text, TextArea, Button, Box, TextField, Skeleton, Select, Separator, AlertDialog, Heading, IconButton } from '@radix-ui/themes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upsertInput, deleteInput } from '../api/inputs';
import toast from 'react-hot-toast';
import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons';

const inputDataTypes = ['Text', 'True/False', 'Number', 'Instruction'];

function InputForm({ isPending: inputIsPending, error: inputError, data: inputData }) {
  const { search } = useLocation();
  const searchURL = (id) => {
    const params = new URLSearchParams(search);
    params.set('input_id', id);
    return params.toString();
  }

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inputId = searchParams.get('input_id');

  const [inputForm, setInputForm] = useState({
    id: undefined,
    name: '',
    required: true,
    data_type: 1,
    permitted_values: [],
    behavior: '',
  });

  const [formErrors, setFormErrors] = useState({})
  
  const queryClient = useQueryClient();
  const inputMutation = useMutation({
    mutationFn: upsertInput,
    onSuccess: (id) => {
      queryClient.invalidateQueries({ queryKey: ['/input'] });
      queryClient.invalidateQueries({ queryKey: ['/input/id'] });
      toast.success('Success! 🎉')

      if (id) {
        navigate({search: searchURL(id)});
      }
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  })

  const deleteMutation = useMutation({
    mutationFn: deleteInput,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/input'] });
      toast.success('Input deleted! 🫠')

      navigate({search: searchURL('')});
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  })

  const resetForm = (id, data) => {
    if (id === 'new' || !data) {
      setInputForm({
        id: undefined,
        name: '',
        required: true,
        data_type: 1,
        permitted_values: [],
        behavior: '',
      });
    } else if (data) {
      setInputForm({
        id: data.data.id,
        name: data.data.name,
        required: true,
        data_type: data.data.data_type,
        permitted_values: data.data.permitted_values,
        behavior: data.data.behavior,
      });
    }
  }

  const { reset: inputReset } = inputMutation;

  useEffect(() => {
    inputReset();
    resetForm(inputId, inputData);
  }, [inputId, inputData, inputReset])

  useEffect(() => {
    inputReset();
  }, [inputForm, inputReset])

  const { error: inputMutationError } = inputMutation;
  useEffect(() => {
    setFormErrors(inputMutationError?.data ?? {})
  }, [inputMutationError]);

  const discardChanges = () => {
    resetForm(inputId, inputData);
  }

  const saveChanges = () => {
    inputMutation.reset();
    inputMutation.mutate({
      ...inputForm,
      permitted_values: inputForm.data_type === 1 ? inputForm.permitted_values : [], 
    });
  }

  const dangerDelete = () => {
    deleteMutation.reset()
    deleteMutation.mutate(inputId);
  }

  const addPermittedValue = () => {
    setInputForm(prev => ({
      ...prev,
      permitted_values: [...prev.permitted_values, ''],
    }))
  }
  
  const deletePermittedValue = (index) => {
    const permitted_values = [...inputForm.permitted_values]
    permitted_values.splice(index, 1);

    setInputForm(prev => ({
      ...prev,
      permitted_values,
    }));
  }

  const setPermittedValue = (item, index) => {
    const permitted_values = [...inputForm.permitted_values]
    permitted_values[index] = item

    setInputForm(prev => ({
      ...prev,
      permitted_values,
    }));
  }

  return (
    <>
      {inputIsPending && (
        <Flex direction="column" gap="5">
          <Skeleton height="56px" width="300px" />
          <Skeleton height="56px" width="150px" />
          <Skeleton height="150px" width="300px" />
          <Skeleton height="120px" width={{initial: "300px", md: "600px"}} />
        </Flex>
      )}

      {!inputError && !inputIsPending && (
        <form>
          <Flex direction="column" gap="5">
            <Box maxWidth="300px">
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Name</Box>
                <TextField.Root
                  name="name"
                  size="2"
                  placeholder="e.g. Course length"
                  value={inputForm.name}
                  onChange={(e) => setInputForm(prev => ({ ...prev, name: e.target.value }))}
                  required
                />
                {formErrors.name && (
                  <Text color="red" size="1" weight="regular">{formErrors.name}</Text>
                )}
              </Text>
            </Box>

            <Box maxWidth="300px">
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Data type</Box>
                <Select.Root
                  size="2"
                  name="data_type"
                  value={inputForm.data_type}
                  onValueChange={value => setInputForm(prev => ({...prev, data_type: Number.parseInt(value)}))}
                  required
                >
                  <Select.Trigger placeholder="Pick a data type" />
                  <Select.Content>
                    <Select.Group>
                      {inputDataTypes.map((item, index) => (
                        <Select.Item key={`data_type.${index}`} value={index + 1}>{item}</Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
                {formErrors.data_type && (
                  <Text as="div" color="red" size="1" weight="regular">{formErrors.data_type}</Text>
                )}
              </Text>
            </Box>

            {inputForm.data_type === 1 && (
              <Flex direction="column" gap="2" maxWidth="300px">
                <Heading as="h6" size="2">Permitted values</Heading>

                <Flex direction="column" gap="2">
                  {inputForm.permitted_values.length === 0 && (
                    <Box maxWidth="300px">
                      <Card>
                        <Text as="div" size="2" weight="bold">✅ All values allowed</Text>
                        <Text as="div" color="gray" size="2">
                          To restrict to some permitted values, add them with the button below
                        </Text>
                      </Card>
                    </Box>
                  )}

                  {inputForm.permitted_values.length > 0 && inputForm.permitted_values.map((item, index) => (
                    <Card key={`input.${inputId}.permitted_values[${index}]`}>
                      <Flex direction="column">
                        <Flex justify="between" gap="4">
                          <Flex flexGrow="1" direction="column" gap="2">
                            <Text as="label" size="2" weight="bold">
                              <Box pb="1">Value</Box>
                              <TextField.Root
                                name={`key[${index}]`}
                                size="2"
                                placeholder="e.g. Large"
                                value={item}
                                onChange={(e) => setPermittedValue(e.target.value, index)}
                                required
                              />
                            </Text>
                          </Flex>
                          
                          <IconButton type="button" variant="soft" size="1" onClick={() => deletePermittedValue(index)}>
                            <Cross1Icon />
                          </IconButton>
                        </Flex>

                        {formErrors[`permitted_values[${index}]`] && (
                          <Box pt="1">
                            <Text color="red" size="1" weight="regular">{formErrors[`permitted_values[${index}]`]}</Text>
                          </Box>
                        )}
                      </Flex>
                    </Card>
                  ))}
                </Flex>

                <Box>
                  <Button color="purple" variant="soft" type="button" onClick={addPermittedValue}>
                    <PlusIcon />
                    Add permitted value
                  </Button>
                </Box>

                {formErrors.permitted_values && (
                  <Text color="red" size="1" weight="regular">{formErrors.permitted_values}</Text>
                )}
              </Flex>
            )}

            <Box maxWidth="600px">
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Behavior</Box>
                <TextArea
                  size="2"
                  rows="4"
                  name="behavior"
                  placeholder="Write the behavior you want to do with the given input."
                  value={inputForm.behavior}
                  onChange={(e) => setInputForm(prev => ({ ...prev, behavior: e.target.value }))}
                  required
                />
                {formErrors.behavior && (
                  <Text color="red" size="1" weight="regular">{formErrors.behavior}</Text>
                )}
              </Text>
            </Box>

            {inputId && inputId !== 'new' && (
              <>
                <Separator my="2" size="4" />

                <Flex direction="row">
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <Button type="button" color="red">Delete input</Button>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content maxWidth="450px">
                      <AlertDialog.Title>Delete input</AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Are you sure? This action is not reversible
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action>
                          <Button type="button" variant="solid" color="red" onClick={dangerDelete}>
                            Delete
                          </Button>
                        </AlertDialog.Action>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </Flex>
              </>
            )}

            <Flex justify="end" align="center" gap="2" px="4" py="2" className="bg-white border-t border-t-gray-200 fixed bottom-0 left-0 right-0 z-30">
              <Button type="button" variant="soft" onClick={discardChanges}>Discard</Button>
              <Button type="button" variant="solid" onClick={saveChanges} color="purple">Save</Button>
            </Flex>
          </Flex>
        </form>
      )}
    </>
  );
}

export {
  InputForm
}