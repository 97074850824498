import { Flex, Text, Button, Box, Heading, Skeleton, Separator, Badge, SegmentedControl } from '@radix-ui/themes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams }  from 'react-router-dom';
import { useState, useEffect } from 'react';

import { SideList } from '../components/SideList';
import { getCourses, getCourse, regenerateCourse } from '../api/courses';
import { CourseForm } from '../components/CourseForm';
import { CourseContent } from '../components/CourseContent';
import toast from 'react-hot-toast';

const courseBadge = [
  {color: 'gray', label: 'Pending'},
  {color: 'yellow', label: 'Generating'},
  {color: 'green', label: 'Processed'},
  {color: 'brown', label: 'Cancelled'},
  {color: 'red', label: 'Failed'},
  {color: 'grass', label: 'Updated'},
  {color: 'yellow', label: 'Incomplete (please wait...)'},
]

function Courses() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('course_id');

  const courses = useQuery({ queryKey: ['/course'], queryFn: getCourses })
  const course = useQuery({ queryKey: ['/course/id'], queryFn: () => courseId && courseId !== 'new' ? getCourse(courseId) : null })

  const [view, setView] = useState('form');

  const { refetch: courseRefetch } = course;
  useEffect(() => {
    courseRefetch();
    setView('form');
  }, [courseRefetch, courseId]);

  const { error: courseError } = course;
  useEffect(() => {
    if (courseError?.status === 404) {
      navigate({search: ''});
    }
  }, [courseError, courseId, navigate]);

  const [refinements, setRefinements] = useState({
    data: '',
    modules: {
      0: {
        data: '',
        lessons: {
          0: {
            data: '',
          },
        }
      }
    }
  });

  const queryClient = useQueryClient();

  const regenerateMutation = useMutation({
    mutationFn: regenerateCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      toast.success('Course generation started! ✅')
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const regenerate = () => {
    regenerateMutation.reset()
    regenerateMutation.mutate({ id: courseId, refinements });
  }

  return (
    <>
      <Flex>
        <SideList
          title="Courses"
          isPending={courses.isPending}
          error={courses.error}
          onRetry={courses.refetch}
          data={courses.data?.data}
          newTitle="New course"
          param="course_id" />

        <Box flexGrow="1">
          <Flex direction="column" gap="4" className="px-6 md:px-10 lg:px-16 py-10 lg:py-20 max-w-[858px] mx-auto">
            {!courseId && !course.isPending && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">No course</Heading>
                <Text color="gray">Select a course from the left or create a new one</Text>
              </Flex>
            )}

            {courseId === 'new' && (
              <Flex direction="column">
                <Heading as="h1" size="8">New course</Heading>
              </Flex>
            )}

            {courseId !== 'new' && course.isPending && (
              <Flex direction="column" gap="2">
                <Skeleton height="40px" width="200px" />
                <Skeleton height="24px" width="250px" />
              </Flex>
            )}

            {courseId !== 'new' && course.error && (
              <Flex direction="column" align="start" gap="2" py="2">
                <Text>Something went wrong 😭</Text>
                <Button variant="soft" onClick={course.refetch}>Retry</Button>
              </Flex>
            )}

            {courseId !== 'new' && course.data && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">{course.data.data.course.name}</Heading>

                <Flex gap="2" align="center">
                  <Text color="gray">ID: {course.data.data.course.id}</Text>
                  <Separator orientation="vertical" size="1" color="gray" />
                  <Text color="gray">Created: {new Date(course.data.data.course.created).toDateString()}</Text>
                  <Separator className="max-sm:hidden" orientation="vertical" size="1" color="gray" />
                  <Badge color={courseBadge[course.data.data.course.status-1].color} className="max-sm:hidden">
                    {courseBadge[course.data.data.course.status-1].label}
                  </Badge>
                </Flex>

                <Box className="md:hidden">
                  <Badge color={courseBadge[course.data.data.course.status-1].color}>
                    {courseBadge[course.data.data.course.status-1].label}
                  </Badge>
                </Box>

                {course.data.data.course.status === 5 && course.data.data.course.failure_reason && (
                  <Box className="bg-red-50 p-2 rounded">
                    <Text size="1" className="break-all" weight="medium" color="red">
                      {course.data.data.course.failure_reason}
                    </Text>
                  </Box>
                )}

                {course.data.data.course.generation > 0 && (
                  <Box>
                    <SegmentedControl.Root value={view} onValueChange={setView}>
                      <SegmentedControl.Item value="form">📝 Form</SegmentedControl.Item>
                      <SegmentedControl.Item value="content">🪄 Content</SegmentedControl.Item>
                    </SegmentedControl.Root>
                  </Box>
                )}
              </Flex>
            )}

            {courseId && view === 'form' && (
              <CourseForm
                isPending={course.isPending}
                error={course.error}
                data={course.data}
                regenerate={regenerate}
              />
            )}

            {courseId !== 'new' && view === 'content' && (
              <CourseContent
                course={course.data}
                refinements={refinements}
                setRefinements={setRefinements}
                regenerate={regenerate}
              />
            )}
          </Flex>
        </Box>
      </Flex>

      <Box height="49px" />
    </>
  );
}

export {
  Courses
};
