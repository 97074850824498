import { Flex, Text, Button, Box, Heading, ScrollArea, Skeleton, IconButton } from '@radix-ui/themes';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import { Link, useLocation, useSearchParams }  from "react-router-dom";
import { useState } from 'react';

function SideListContent({ title, isPending, error, onRetry, data, newTitle, onClick, param }) {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const selectedId = searchParams.get(param);
  const selectedClassName = (id) => id.toString() === selectedId ? 'bg-purple-200' : 'hover:bg-purple-100';
  const searchURL = (id) => {
    const params = new URLSearchParams(search);
    params.set(param, id);
    return params.toString();
  }

  return (
    <ScrollArea py="9" scrollbars="vertical">
      <Box px="5" pt="5" pb="4" gap="2">
        <Heading as="h4" size="3" >{title}</Heading>
      </Box>

      {isPending && (
        <Flex direction="column" px="5" gap="3"> 
          <Skeleton width="226px" height="40px" className="rounded-sm" />
          <Skeleton width="226px" height="40px" className="rounded-sm" />
          <Skeleton width="226px" height="40px" className="rounded-sm" />
          <Skeleton width="226px" height="40px" className="rounded-sm" />
          <Skeleton width="226px" height="40px" className="rounded-sm" />
        </Flex>
      )}

      {error && (
        <Flex direction="column" align="start" gap="2" py="2" px="5">
          <Text>Something went wrong 😭</Text>
          <Button variant="soft" onClick={onRetry}>Retry</Button>
        </Flex>
      )}

      {data && (
        <Flex direction="column" px="3" gap="1" className="w-[226px]">       
          {data.map(item => (
            <Link key={`sidelist-${item.id}`} to={{search: searchURL(item.id)}} className={`overflow-hidden truncate py-2 px-3 rounded-full ${selectedClassName(item.id)}`} onClick={onClick}>
              {item.name}
            </Link>
          ))}

          <Link to={{search: searchURL('new')}} className={`py-2 px-3 rounded-full ${selectedClassName('new')}`} onClick={onClick}>
            {newTitle}
          </Link>
        </Flex>
      )}
    </ScrollArea>
);
}

function SideList({ title, isPending, error, onRetry, data, newTitle, param }) {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => setVisible(prev => !prev);

  return (
    <>
      <Box px="4" py="2" className="fixed z-50 bottom-0 left-0 md:hidden">
        <IconButton variant="soft" onClick={toggleVisible}>
          <HamburgerMenuIcon />
        </IconButton>
      </Box>

      <Box className="max-md:hidden w-[250px]">
        <SideListContent
          title={title}
          isPending={isPending}
          error={error}
          onRetry={onRetry}
          data={data}
          newTitle={newTitle}
          onClick={() => setVisible(false)}
          param={param}
        />
      </Box>

      {visible && (
        <Box className="md:hidden bg-white fixed top-0 left-0 bottom-0 right-0 mt-[49px] z-40">
          <SideListContent
            title={title}
            isPending={isPending}
            error={error}
            onRetry={onRetry}
            data={data}
            newTitle={newTitle}
            onClick={() => setVisible(false)}
            param={param}
          />
        </Box>
      )}
    </>
  );
}

export {
  SideList
}