import { Em, Flex, Text, TextArea, Button, Box, Popover, Separator, Heading, IconButton, Skeleton, Select, AlertDialog } from '@radix-ui/themes';
import { CheckCircledIcon, EnvelopeClosedIcon, EnvelopeOpenIcon, MagicWandIcon } from '@radix-ui/react-icons';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { getCourseContent, getCourseRefinement } from '../api/courses';

const courseLanguages = [
  {key: 'spanish', value: 'Spanish'},
  {key: 'english', value: 'English'},
  {key: 'portuguese', value: 'Portuguese'},
]

function CourseContent({ course: courseData, refinements, setRefinements, regenerate }) {
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('course_id');

  const content = useQuery({ queryKey: ['/course/id/content'], queryFn: () => courseId && courseId !== 'new' ? getCourseContent(courseId, courseLanguage, courseGeneration) : null })
  const courseRefinements = useQuery({ queryKey: ['/course/id/refinement'], queryFn: () => courseId && courseId !== 'new' ? getCourseRefinement(courseId) : null })

  const [courseLanguage, setCourseLanguage] = useState(courseData.data.course.languages[0]);
  const [courseGeneration, setCourseGeneration] = useState(courseData.data.course.generation);

  const { refetch: contentRefetch } = content;
  const { refetch: courseRefinementsRefetch } = courseRefinements;
  useEffect(() => {
    contentRefetch();
    courseRefinementsRefetch();
  }, [contentRefetch, courseRefinementsRefetch, courseLanguage, courseGeneration, courseId]);

  const isProcessing = () => courseId !== 'new' && ![3, 4, 5, 6].includes(courseData?.data.course.status);

  const setCourseRefinement = useCallback((data) => setRefinements(prev => ({ ...prev, data })), [setRefinements]);

  const setModuleRefinement = useCallback((modulePosition, data) => setRefinements(prev => ({
    ...prev,
    modules: {
      ...prev.modules,
      [modulePosition]: {
        ...(prev.modules[modulePosition] ?? { data: '', lessons: {} }),
        data
      }
    }
  })), [setRefinements]);

  const setLessonRefinement = useCallback((modulePosition, lessonPosition, data) => setRefinements(prev => ({
    ...prev,
    modules: {
      ...prev.modules,
      [modulePosition]: {
        ...(prev.modules[modulePosition] ?? { data: '', lessons: {} }),
        lessons: {
          ...(prev.modules[modulePosition]?.lessons ?? {}),
          [lessonPosition]: {
            ...(prev.modules[modulePosition]?.lessons[lessonPosition] ??  { data: '' }),
            data
          }
        }
      }
    }
  })), [setRefinements]);

  const { data: courseRefinementsData } = courseRefinements;
  useEffect(() => {
    courseRefinementsData?.data.forEach(refine => {
      if (refine.type === 1) {
        setCourseRefinement(refine.refinement);
      } else if (refine.type === 2) {
        setModuleRefinement(refine.module_position, refine.refinement);
      } else {
        setLessonRefinement(refine.module_position, refine.lesson_position, refine.refinement);
      }
    })
  }, [courseRefinementsData, setCourseRefinement, setModuleRefinement, setLessonRefinement])

  return (
    <>
      {content.isPending && (
        <Flex direction="column" gap="5">
          <Skeleton height="40px" width={{initial: "300px", md: "500px"}} />
          <Skeleton height="50px" width="200px" />
          <Separator orientation="horizontal" size="4" />
          <Skeleton height="150px" width={{initial: "300px", md: "600px"}} />
          <Skeleton height="150px" width={{initial: "300px", md: "600px"}} />
          <Skeleton height="150px" width={{initial: "300px", md: "600px"}} />
        </Flex>
      )}

      {!content.error && !content.isPending && content.data && (
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="4">
            <Flex gap="1" justify="between" align="start">
              <Flex direction="column" gap="1">
                <Flex gap="2">
                  <Text size="1" weight="medium" color="gray">Course</Text>
                  <Separator orientation="vertical" />
                  <Text size="1" color="gray" weight="medium">{courseData.data.course.name}</Text>
                </Flex>
                <Text size="2">{courseData.data.course.description}</Text>
              </Flex>

              {courseGeneration === courseData.data.course.generation && courseRefinements.data && (
                <RefinementPopover
                  type="course"
                  isProcessing={isProcessing()}
                  data={refinements.data}
                  setData={setCourseRefinement}
                />
              )}
            </Flex>

            <Flex gap="3">
              <Text as="label" size="1" weight="medium">
                <Box pb="1">Language</Box>
                <Select.Root value={courseLanguage} onValueChange={setCourseLanguage}>
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Group>
                      {courseLanguages.map(({ key, value }) => (
                        <Select.Item key={key} value={key}>{value}</Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </Text>

              <Text as="label" size="1" weight="medium">
                <Box pb="1">Generation</Box>
                <Select.Root value={courseGeneration} onValueChange={setCourseGeneration}>
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Group>
                      {Array.from({length: courseData.data.course.generation}).map((_, index) => (
                        <Select.Item key={index+1} value={index+1}>Generation #{index+1}</Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </Text>
            </Flex>

            {courseGeneration !== courseData.data.course.generation && (
              <Text size="1" color="orange">
                You must be in the latest generation in order to add refinements
              </Text>
            )}

            <Separator orientation="horizontal" size="4" />
          </Flex>

          <Flex direction="column" gap="4">
            {content.data.data.modules.length === 0 && (
              <Flex direction="column" gap="1">
                <Text size="1" weight="medium" color="gray">No modules</Text>
                <Heading as="h3" size="3">
                  There are no modules for this course's generation/language or they are being generated
                </Heading>
              </Flex>
            )}

            {content.data.data.modules.map((module) => (
              <Flex key={`module.${module.position}`} direction="column" gap="4">
                <Flex gap="1" justify="between" align="start">
                  <Flex direction="column" gap="1">
                    <Text size="1" weight="medium" color="gray">Module {module.position+1}</Text>
                    <Heading as="h3" size="3">{module.name}</Heading>
                  </Flex>

                  {courseGeneration === courseData.data.course.generation && courseRefinements.data && (
                    <RefinementPopover
                      type="module"
                      isProcessing={isProcessing()}
                      data={refinements.modules[module.position]?.data ?? ''}
                      setData={(data) => setModuleRefinement(module.position, data)}
                    />
                  )}
                </Flex>
                
                <Flex direction="column" gap="4" pl={{initial: "3", lg: "5"}}>
                  {(module.lessons.length === 0 || !module.complete) && (
                    <Flex direction="column" gap="1">
                      <Text size="1" weight="medium" color="gray">No lessons</Text>
                      <Heading as="h3" size="3">
                        There are no lessons for this module or they are being generated
                      </Heading>
                    </Flex>
                  )}

                  {module.complete && module.lessons.map(lesson => (
                    <Flex key={`lesson.${lesson.position}`} direction="column" gap="2">
                      <Flex gap="1" justify="between" align="start">
                        <Flex direction="column" gap="1">
                          <Text size="1" weight="medium" color="gray">Lesson {lesson.position+1}</Text>
                          <Heading as="h4" size="2">{lesson.data.title}</Heading>
                        </Flex>

                        {courseGeneration === courseData.data.course.generation && courseRefinements.data && (
                          <RefinementPopover
                            type="lesson"
                            isProcessing={isProcessing()}
                            data={refinements.modules[module.position]?.lessons[lesson.position]?.data ?? ''}
                            setData={(data) => setLessonRefinement(module.position, lesson.position, data)}
                          />
                        )}
                      </Flex>

                      <Flex direction="column" gap="2">
                        {lesson.data.contents.map((message, index) => (
                          <Flex key={`message.${index}`} gap="2" align="start">
                            <Box>
                              <EnvelopeClosedIcon height="24px" width="12px" />
                            </Box>
                            <Text>
                              {index === 0 && lesson.data.media && (
                                <Box><Em>[Inserte una imagen ú objeto de acompanamiento aquí]</Em></Box>
                              )}
                              {message}
                            </Text>
                          </Flex>
                        ))}

                        <Separator size="4" orientation="vertical" />

                        {lesson.data.activity && (
                          <Flex gap="1" direction="column">
                            <Flex gap="1" direction="column">
                              <Text size="1" weight="medium" color="gray">Activity</Text>
                              <Heading as="h4" size="2">{lesson.data.activity.title}</Heading>
                            </Flex>

                            <Flex gap="2" align="start">
                              <Box>
                                <EnvelopeClosedIcon height="24px" width="10px" />
                              </Box>
                              <Text size="2">
                                {lesson.data.activity.media && (
                                  <Box><Em>[Inserte una imagen ú objeto de acompanamiento aquí]</Em></Box>
                                )}
                                {lesson.data.activity.content}
                              </Text>
                            </Flex>

                            {lesson.data.activity.response && (
                              <Flex gap="2" align="start">
                                <Box>
                                  <EnvelopeOpenIcon height="24px" width="10px" />
                                </Box>
                                <Text size="2">
                                  <strong>Response: </strong>
                                  {lesson.data.activity.response}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        )}

                        <Separator size="4" orientation="vertical" />

                        {lesson.data.quiz && (
                          <Flex gap="1" direction="column">
                            <Flex gap="1" direction="column">
                              <Text size="1" weight="medium" color="gray">Quiz</Text>
                              <Heading as="h4" size="2">{lesson.data.quiz.title}</Heading>
                            </Flex>

                            <Flex gap="2" align="start">
                              <Box>
                                <EnvelopeClosedIcon height="24px" width="10px" />
                              </Box>
                              <Text size="2">
                                {lesson.data.quiz.media && (
                                  <Box><Em>[Inserte una imagen ú objeto de acompanamiento aquí]</Em></Box>
                                )}
                                {lesson.data.quiz.content}
                              </Text>
                            </Flex>

                            <Flex gap="1" direction="column">
                              {lesson.data.quiz.choices.map((choice, index) => (
                                <Flex key={`choice.${index}`} gap="2" align="start">
                                  <Box>
                                    <CheckCircledIcon height="24px" width="10px" />
                                  </Box>
                                  <Text size="2" className={choice === lesson.data.quiz.answer ? 'bg-green-200' : ''}>
                                    {choice}
                                  </Text>
                                </Flex>
                              ))}
                            </Flex>


                            {lesson.data.quiz.response && (
                              <Flex gap="2" align="start">
                                <Box>
                                  <EnvelopeOpenIcon height="24px" width="10px" />
                                </Box>
                                <Text size="2">
                                  <strong>Response: </strong>
                                  {lesson.data.quiz.response}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>

                {module.position < content.data.data.modules.length - 1 && (
                  <Separator orientation="horizontal" size="4" my="4" />
                )}
              </Flex>
            ))}
          </Flex>

          <Flex justify="end" align="center" gap="2" px="4" py="2" className="bg-white border-t border-t-gray-200 fixed bottom-0 left-0 right-0 z-30">
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button type="button" variant="solid" color="purple" disabled={isProcessing()}>
                  Regenerate
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>
                  Regenerate course
                </AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure? You won't be able to make changes until it's processed
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button type="button" variant="solid" color="purple" onClick={regenerate}>
                      Regenerate
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </Flex>
        </Flex>
      )}
    </>
  );
}

function RefinementPopover({ type, data, setData, isProcessing }) {
  const titleType = type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Box position="relative">
          {data && (
            <Box className="absolute top-[-5px] right-[-5px] h-2.5 w-2.5 rounded-full bg-red-500" />
          )}

          <IconButton variant="soft" color="purple" size="1">
            <MagicWandIcon width="16px" height="16px" />
          </IconButton>
        </Box>
      </Popover.Trigger>
      <Popover.Content width="360px">
        <Flex direction="column" gap="2">
          <Heading as="h6" size="2" weight="bold">
            {titleType} refinement
          </Heading>

          <Box flexGrow="1">
            <TextArea
              value={data}
              disabled={isProcessing}
              onChange={(e) => setData(e.target.value)}
              size="2"
              rows="4"
              placeholder={`Write a refinement you want to add to this ${type}`}
            />
          </Box>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}

export {
  CourseContent
}