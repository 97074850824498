import { fetchJSON } from "./utils";

async function getCourses() {
  const path = '/course';
  return await fetchJSON(path, 'GET', {});
}

async function getCourse(id) {
  const path = `/course/${id}`;
  return await fetchJSON(path, 'GET', {});
}

async function getCourseContent(id, language, generation) {
  const path = `/course/${id}/content?language=${language}&generation=${generation}`;
  return await fetchJSON(path, 'GET', {});
}

async function getCourseRefinement(id) {
  const path = `/course/${id}/refinement`;
  return await fetchJSON(path, 'GET', {});
}

async function upsertCourse({ id, name, description, languages, builder_id, knowledges: rawKnowledges, responses: rawResponses }) {  
  const responses = rawResponses.map(r => ({ input_id: r.input_id, value: r.value }))
  const knowledges = rawKnowledges.map(k => k.id);
  
  if (id) {
    const path = `/course/${id}`;
    const body = { name, description, languages };
    await fetchJSON(path, 'PATCH', { body: JSON.stringify(body) });
    return id;
  }

  const path = '/course';
  const body = { name, description, languages, responses, knowledges, builder_id };
  const res = await fetchJSON(path, 'POST', { body: JSON.stringify(body) });
  return res.data;
}

async function regenerateCourse({ id, refinements }) {
  const path = `/course/${id}/regenerate`;
  const body = refinements;
  return await fetchJSON(path, 'POST', { body: JSON.stringify(body) });
}

async function cancelCourse(id) {
  const path = `/course/${id}/cancel`;
  return await fetchJSON(path, 'POST', {});
}

async function deleteCourse(id) {
  const path = `/course/${id}`;
  return await fetchJSON(path, 'DELETE', {});
}

export {
  getCourse,
  getCourses,
  getCourseContent,
  getCourseRefinement,
  upsertCourse,
  regenerateCourse,
  cancelCourse,
  deleteCourse
}
