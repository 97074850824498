import { Flex, Text, Button, Box, Heading, Skeleton, Separator } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams }  from 'react-router-dom';
import { useEffect } from 'react';

import { SideList } from '../components/SideList';
import { getBuilders, getBuilder } from '../api/builders';
import { BuilderForm } from '../components/BuilderForm';

function Builders() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const builderId = searchParams.get('builder_id');

  const builders = useQuery({ queryKey: ['/builder'], queryFn: getBuilders })
  const builder = useQuery({ queryKey: ['/builder/id'], queryFn: () => builderId && builderId !== 'new' ? getBuilder(builderId) : null })

  const { refetch: builderRefetch } = builder;
  useEffect(() => {
    builderRefetch();
  }, [builderRefetch, builderId]);

  const { error: builderError } = builder;
  useEffect(() => {
    if (builderError?.status === 404) {
      navigate({search: ''});
    }
  }, [builderError, builderId, navigate]);

  return (
    <>
      <Flex>
        <SideList
          title="Builders"
          isPending={builders.isPending}
          error={builders.error}
          onRetry={builders.refetch}
          data={builders.data?.data}
          newTitle="New builder"
          param="builder_id" />

        <Box flexGrow="1">
          <Flex direction="column" gap="4" className="px-6 md:px-10 lg:px-16 py-10 lg:py-20 max-w-[858px] mx-auto">
            {!builderId && !builder.isPending && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">No builder</Heading>
                <Text color="gray">Select a builder from the left or create a new one</Text>
              </Flex>
            )}

            {builderId === 'new' && (
              <Flex direction="column">
                <Heading as="h1" size="8">New builder</Heading>
              </Flex>
            )}

            {builderId !== 'new' && builder.isPending && (
              <Flex direction="column" gap="2">
                <Skeleton height="40px" width="200px" />
                <Skeleton height="24px" width="250px" />
              </Flex>
            )}

            {builderId !== 'new' && builder.error && (
              <Flex direction="column" align="start" gap="2" py="2">
                <Text>Something went wrong 😭</Text>
                <Button variant="soft" onClick={builders.refetch}>Retry</Button>
              </Flex>
            )}

            {builderId !== 'new' && builder.data && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">{builder.data.data.builder.name}</Heading>
                <Flex gap="2" align="center">
                  <Text color="gray">ID: {builder.data.data.builder.id}</Text>
                  <Separator orientation="vertical" size="1" color="gray" />
                  <Text color="gray">Created: {new Date(builder.data.data.builder.created).toDateString()}</Text>
                </Flex>
              </Flex>
            )}

            {builderId && (
              <BuilderForm isPending={builder.isPending} error={builder.error} data={builder.data} />
            )}
          </Flex>
        </Box>
      </Flex>

      <Box height="49px" />
    </>
  );
}

export {
  Builders
};
