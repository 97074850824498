import { fetchForm, fetchJSON } from "./utils";

async function getKnowledge(id) {
  const path = `/knowledge/${id}`;
  return fetchJSON(path, 'GET', {});
}

async function getKnowledges() {
  const path = '/knowledge';
  return fetchJSON(path, 'GET', {});
}

async function upsertKnowledge({ id, name, course_id, source_file }) {
  if (id) {
    return id;
  }

  const formData = new FormData();
  formData.append('name', name);
  formData.append('file', source_file);
  
  if (course_id) {
    formData.append('course_id', course_id);
  }

  const path = '/knowledge';
  const res = await fetchForm(path, 'POST', { body: formData });
  return res.data;
}

async function deleteKnowledge(id) {
  const path = `/knowledge/${id}`;
  return await fetchJSON(path, 'DELETE', {});
}

export {
  getKnowledge,
  getKnowledges,
  upsertKnowledge,
  deleteKnowledge
}
