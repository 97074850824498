import { Flex, Text, Button, Box, Heading, Skeleton, Separator } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams }  from 'react-router-dom';
import { useEffect } from 'react';

import { SideList } from '../components/SideList';
import { getInputs, getInput } from '../api/inputs';
import { InputForm } from '../components/InputForm';

function Inputs() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inputId = searchParams.get('input_id');

  const inputs = useQuery({ queryKey: ['/input'], queryFn: getInputs })
  const input = useQuery({ queryKey: ['/input/id'], queryFn: () => inputId && inputId !== 'new' ? getInput(inputId) : null })

  const { refetch: inputRefetch } = input;
  useEffect(() => {
    inputRefetch();
  }, [inputRefetch, inputId]);

  const { error: inputError } = input;
  useEffect(() => {
    if (inputError?.status === 404) {
      navigate({search: ''});
    }
  }, [inputError, inputId, navigate]);

  return (
    <>
      <Flex>
        <SideList
          title="Inputs"
          isPending={inputs.isPending}
          error={inputs.error}
          onRetry={inputs.refetch}
          data={inputs.data?.data}
          newTitle="New input"
          param="input_id" />

        <Box flexGrow="1">
          <Flex direction="column" gap="4" className="px-6 md:px-10 lg:px-16 py-10 lg:py-20 max-w-[858px] mx-auto">
            {!inputId && !input.isPending && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">No input</Heading>
                <Text color="gray">Select an input from the left or create a new one</Text>
              </Flex>
            )}

            {inputId === 'new' && (
              <Flex direction="column">
                <Heading as="h1" size="8">New input</Heading>
              </Flex>
            )}

            {inputId !== 'new' && input.isPending && (
              <Flex direction="column" gap="2">
                <Skeleton height="40px" width="200px" />
                <Skeleton height="24px" width="250px" />
              </Flex>
            )}

            {inputId !== 'new' && input.error && (
              <Flex direction="column" align="start" gap="2" py="2">
                <Text>Something went wrong 😭</Text>
                <Button variant="soft" onClick={inputs.refetch}>Retry</Button>
              </Flex>
            )}

            {inputId !== 'new' && input.data && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">{input.data.data.name}</Heading>
                <Flex gap="2" align="center">
                  <Text color="gray">ID: {input.data.data.id}</Text>
                  <Separator orientation="vertical" size="1" color="gray" />
                  <Text color="gray">Created: {new Date(input.data.data.created).toDateString()}</Text>
                </Flex>
              </Flex>
            )}

            {inputId && (
              <InputForm isPending={input.isPending} error={input.error} data={input.data} />
            )}
          </Flex>
        </Box>
      </Flex>

      <Box height="49px" />
    </>
  );
}

export {
  Inputs
};
