import { Box, DropdownMenu, Heading, Flex, Avatar, Text, Button, Separator } from '@radix-ui/themes';
import { Builders } from './pages/Builders';
import { Inputs } from './pages/Inputs';
import { NotFound } from './pages/NotFound';
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import { Courses } from './pages/Courses';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Builders />} />
        <Route path="inputs" element={<Inputs />} />
        <Route path="courses" element={<Courses />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const { pathname, search } = useLocation();
  const navVariant = (path) => path === pathname ? 'solid' : 'soft';

  return (
    <Flex direction="column">
      <Flex justify="between" align="center" gap="2" px="4" py="3" className="bg-white border-b border-b-gray-200 fixed top-0 left-0 right-0 z-30">
        <Flex gap="2" align="center" className="max-md:hidden">
          <Heading as="h1" size="4">Wisdom</Heading>
          <Separator orientation="vertical" size="1" />
          <Heading as="h6" size="1">La Teacher</Heading>
        </Flex>
        <Heading as ="h1" size="4" className="md:hidden">W</Heading>

        <Flex gap="2" align="center">
          <Button size="1" radius="full" variant={navVariant('/')} highContrast asChild>
            <Link to={{pathname: '/', search: search}}>Builders</Link>
          </Button>
          <Button size="1" radius="full" variant={navVariant('/inputs')} highContrast asChild>
            <Link to={{pathname: '/inputs', search: search}}>Inputs</Link>
          </Button>
          <Button size="1" radius="full" variant={navVariant('/courses')} highContrast asChild>
            <Link to={{pathname: '/courses', search: search}}>Courses</Link>
          </Button>
        </Flex>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button size="1" radius="small" variant="ghost">
              <Flex gap="2" align="center">
                <Flex gap="1" align="center">
                  <Avatar
                    src="https://i.pinimg.com/originals/ec/8e/f6/ec8ef60560fcf6f954485f2026aa355b.jpg"
                    fallback="M"
                    radius="full"
                    size="1"
                    />
                  <Text size="1" className="max-md:hidden">Marceline</Text>
                </Flex>
                <DropdownMenu.TriggerIcon className="max-md:hidden" />
              </Flex>
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item>
              Settings
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item color="red">
              Logout
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <Box height="49px" />

      <Outlet />
    </Flex>
  );
}

export default App;
