const baseUrl = process.env.REACT_APP_API_BASE_URL;

class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.name = "APIError";
    this.status = status;
    this.data = data;
  }
}

async function fetchJSON(path, method, args) {
  const res = await fetch(`${baseUrl}${path}`, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0LCJyb2xlIjoxLCJleHAiOjE3Mjc1OTA1MjN9.QVxLk4SXJtmGm7uHemMGsUqVs_invS7C_usoH6Ci5TQ',
    },
    ...args
  });
  const data = await res.json();

  if (!res.ok) {
    throw new APIError(res.statusText, res.status, data);
  }

  return data;
}

async function fetchForm(path, method, args) {
  const res = await fetch(`${baseUrl}${path}`, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0LCJyb2xlIjoxLCJleHAiOjE3Mjc1OTA1MjN9.QVxLk4SXJtmGm7uHemMGsUqVs_invS7C_usoH6Ci5TQ',
    },
    ...args
  });
  const data = await res.json();

  if (!res.ok) {
    throw new APIError(res.statusText, res.status, data);
  }

  return data;
}

export {
  fetchJSON,
  fetchForm,
}
