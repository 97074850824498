import { Flex, Text, Link as RadixLink } from "@radix-ui/themes";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <Flex direction="column" align="center" gap="2">
      <Text>Nothing to see here! 👀</Text>
      <RadixLink asChild>
        <Link to="/">Go to the home page</Link>
      </RadixLink>
    </Flex>
  );
}

export {
  NotFound
}